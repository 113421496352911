<template>
  <div>
    <section class="section has-small-padding-vertical">
      <div class="container narrow-container">
        <div class="has-text-centered">
          <h1 class="is-size-1 has-font-alternate has-text-weight-bold">Floom x Wavve</h1>
          <div class="divider"></div>
          <div class="video-container is-clearfix">
            <!-- eslint-disable -->
            <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
              <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                <div
                  class="wistia_embed wistia_async_e1is6lu9hj videoFoam=true"
                  style="height:100%;position:relative;width:100%"
                >
                  <div
                    class="wistia_swatch"
                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
                  >
                    <img
                      src="https://fast.wistia.com/embed/medias/e1is6lu9hj/swatch"
                      style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
                      alt=""
                      aria-hidden="true"
                      onload="this.parentNode.style.opacity=1;"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- eslint-enable -->
          </div>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h2 class="is-size-3 has-font-alternate has-text-centered">Browse the Templates</h2>
        <div class="divider"></div>
        <div class="columns is-multiline">
          <div
            class="column is-one-third-desktop is-half-tablet has-margin-top has-text-centered"
            v-for="template in templates"
            :key="template.name"
          >
            <div class="card__cover">
              <video
                v-if="template.video"
                :src="template.video"
                preload="true"
                playsinline="true"
                autoplay="true"
                loop="true"
                muted="muted"
              ></video>
              <img v-else :src="template.image" :alt="name" />
            </div>
            <h4 class="is-size-6 has-text-weight-bold">{{ template.name }}</h4>
            <p class="is-size-6-7 three-quarters-width">{{ template.description }}</p>
            <router-link :to="`/service/${template.slug || template.id}`">
              <el-button class="has-margin-top is-uppercase" type="text" size="medium">VIEW</el-button>
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="how-templates-work">
      <div class="container narrower-container">
        <h2 class="is-size-3 has-font-alternate has-text-centered">How These Templates Work</h2>
        <div class="divider"></div>
        <el-steps class="has-large-margin-top" :active="activeStep" finish-status="success" :align-center="true">
          <el-step title="Step 1"></el-step>
          <el-step title="Step 2"></el-step>
          <el-step title="Step 3"></el-step>
        </el-steps>
        <div v-if="activeStep === 0" class="has-large-margin-vertical">
          <div class="columns is-vcentered">
            <div class="column is-two-thirds">
              <el-image
                fit="contain"
                style="width: 100%; height: 300px"
                :src="require('@/assets/wavve-templates/step-1.png')"
              >
              </el-image>
            </div>
            <div class="column is-one-third">
              <p class="has-text-weight-bold is-size-5 has-small-margin-bottom">1. Choose a Floom Template</p>
              <p class="is-size-6-7">
                Pick out a design above and create your personalized template on Floom. Download the image to your
                computer or phone.
              </p>
              <el-button class="has-margin-top" type="primary" size="small" @click="nextStep">Next step</el-button>
            </div>
          </div>
        </div>
        <div v-if="activeStep === 1" class="has-large-margin-vertical">
          <div class="columns is-vcentered">
            <div class="column is-two-thirds">
              <el-image
                fit="contain"
                style="width: 100%; height: 300px"
                :src="require('@/assets/wavve-templates/step-2.png')"
              >
              </el-image>
            </div>
            <div class="column is-one-third">
              <p class="has-text-weight-bold is-size-5 has-small-margin-bottom">2. Upload Your Image From Floom</p>
              <p class="is-size-6-7">
                Create a new design on
                <a class="has-text-weight-bold" href="https://app.wavve.co/editor">Wavve</a>. Make sure to pick the
                right size and then upload the image you downloaded from Floom. It will be sized perfectly to fit the
                Wavve design specs. You can now add a waveform, progress bar, or additional text to your design before
                saving.
              </p>
              <el-button class="has-margin-top" type="primary" size="small" @click="nextStep">Next step</el-button>
            </div>
          </div>
        </div>
        <div v-if="activeStep === 2" class="has-large-margin-vertical">
          <div class="columns is-vcentered">
            <div class="column is-two-thirds">
              <el-image
                fit="contain"
                style="width: 100%; height: 300px"
                :src="require('@/assets/wavve-templates/step-3.png')"
              >
              </el-image>
            </div>
            <div class="column is-one-third">
              <p class="has-text-weight-bold is-size-5 has-small-margin-bottom">3. Create a Video</p>
              <p class="is-size-6-7">
                Save your design and use it to create a Wavve video. Voila!
              </p>
              <el-button class="has-margin-top" type="primary" size="small" @click="nextStep">Start Again</el-button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section has-large-margin-vertical" style="background-color: #2a273d">
      <div class="container has-text-centered has-large-padding-vertical">
        <h2 class="is-size-2 has-text-white has-font-alternate">Looking for something else?</h2>
        <p class="has-text-grey-lighter has-margin-bottom is-size-5 has-font-alternate">
          Tell us what kind of templates you'd love to see.
        </p>
        <br />
        <a href="mailto:rob@floom.app" target="_blank">
          <el-button type="default">Get In Touch</el-button>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'WavveTemplates',
  data() {
    return {
      activeStep: 0,
      video: null,
      templates: [
        {
          name: 'Vertical Magazine Cover',
          sizeType: 'square',
          image: 'https://s3.amazonaws.com/floom-resources/wavve-template/foruth.png',
          video: 'https://s3.amazonaws.com/sls-wavve-out/dd9ed035-1ac0-4108-9d9f-00e1794cac61.mp4',
          description: 'An eye-catching design sized just right for IGTV.',
          slug: 'wavve-template-vertical-magazine',
          id: '5326685b-51ff-4de2-873b-98b7144eecff',
        },
        {
          name: 'Square Magazine Cover',
          sizeType: 'square',
          image: 'https://s3.amazonaws.com/floom-resources/wavve-template/fifth.png',
          video: 'https://s3.amazonaws.com/sls-wavve-out/f53f247f-f2dd-4f6c-b60a-99bdff684709.mp4',
          description: 'The perfect template for getting people to stop and listen on instagram.',
          slug: 'wavve-template-square-magazine',
          id: '5326685b-51ff-4de2-873b-98b7144eecff',
        },
        {
          name: 'Minimalist with Color',
          sizeType: 'square',
          image: 'https://s3.amazonaws.com/floom-resources/wavve-template/421abf3b-6cac-4347-a002-e24c41e0da7e.png',
          video: 'https://s3.amazonaws.com/sls-wavve-out/d8fa1186-244f-4154-8abc-11363e6bf812.mp4',
          description: 'An simple design perfect for IGTV and IG + FB stories.',
          slug: 'wavve-template-minimalist-color',
          id: '9151a486-ad15-4037-aa1d-e61c2f54cb84',
        },
        {
          name: 'Cover Art',
          sizeType: 'square',
          image: 'https://s3.amazonaws.com/floom-resources/cover/96211be3-caba-4e62-96ce-f82c731be513.jpeg',
          video: 'https://s3.amazonaws.com/sls-wavve-out/62927ab4-8354-4c63-9045-eb7de1609f52.mp4',
          description: 'Show off your artwork while leaving room at the bottom for a waveform or captions.',
          slug: 'wavve-template-cover-art',
          id: '1a21990c-d7af-48d7-9a74-e1783c81077f',
        },
        {
          name: 'Interview',
          sizeType: 'square',
          image: 'https://s3.amazonaws.com/floom-resources/dark-cover/85f6f14c-29ea-4b61-a8dd-de100e831ba6.png',
          video: 'https://s3.amazonaws.com/sls-wavve-out/03653006-9920-4894-ab0d-426a327d94a0.mp4',
          description: 'Great for interviews. Add a headshot, name, and description of your podcast guest. ',
          slug: 'wavve-template-interview',
          id: '1a21990c-d7af-48d7-9a74-e1783c81077f',
        },
      ],
    }
  },
  created() {
    // add wistia video
    const wistiaEmbedA = document.createElement('script')
    wistiaEmbedA.setAttribute('src', 'https://fast.wistia.com/embed/medias/e1is6lu9hj.jsonp')
    wistiaEmbedA.setAttribute('async', true)
    document.head.appendChild(wistiaEmbedA)
    const wistiaEmbedB = document.createElement('script')
    wistiaEmbedB.setAttribute('src', 'https://fast.wistia.com/assets/external/E-v1.js')
    wistiaEmbedB.setAttribute('async', true)
    document.head.appendChild(wistiaEmbedB)
  },
  mounted() {
    this.video = document.querySelector('video')
    document.dispatchEvent(new Event('x-app-rendered'))
  },
  methods: {
    muteUnmute() {
      this.video.muted = !this.video.muted
    },
    nextStep() {
      this.activeStep += 1
      if (this.activeStep > 2) {
        this.activeStep = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.headline-video {
  max-height: 450px;
}

.card__cover {
  text-align: center;
  img {
    height: 200px;
    margin: 0 auto 12px;
  }
  video {
    height: 200px;
    margin: 0 auto 12px;
  }
}

.three-quarters-width {
  width: 75%;
  margin: 0 auto;
}
</style>
